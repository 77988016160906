import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import audioApi from '../../../apis/audioApi';
import { iTempGoldTile } from '../../interfaces/activityInterfaces';
import { iAudioOptions } from '../../interfaces/audioInterfaces';
import { ValidationErrors, iThunkApi } from '../../interfaces/reduxInterfaces';

export const addAudioToTempGoldTile = createAsyncThunk<
    iTempGoldTile, // return type
    iAudioOptions, // args type
    iThunkApi // thunkAPI type
>(
    "audio/addAudioToTempGoldTile",
    async (options, { rejectWithValue, dispatch, getState }) => {
        try {
            const formData = new FormData();
            formData.append('file', options.file, options.file.name);
            const { data: tempGoldTile } = await audioApi.post(`addAudioToTempGoldTile/${options.uniqueUploadId}`, formData, {
                onUploadProgress: progressEvent => {
                    dispatch(setProgress(progressEvent.loaded / progressEvent.total));
                },
                headers: { "Content-Type": "multipart/form-data" },
            });
            return tempGoldTile;
        } catch (err) {
            console.log("err: ", err);
            let error: AxiosError<ValidationErrors> = err; // cast the error for access
            if (error.code === 'ECONNABORTED') {
                return rejectWithValue("Network timeout error!");
            } else if (error.request) {
                if (error.response) {
                    console.log("error.response: ", error.response);
                }
                return rejectWithValue("Server refused to process request");
            } else {
                if (error.response) {
                    console.log("error.response: ", error.response);
                } else {
                    console.log("audio/addAudioToTempGoldTile,  error: ", error);
                }
                return rejectWithValue("Error happened! Unable to upload to the server");
            }
        }
    }
);

//No support for deleting audios...

const initialState = {
    isLoading: false,
    isError: false,
    tempGoldTile: null,
    uploadProgress: 0,
};

const audioSlice = createSlice({
    name: 'audio',
    initialState,
    reducers: {
        setProgress(state, action) {
            // console.log("action: ", action)
            state.uploadProgress = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addAudioToTempGoldTile.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(addAudioToTempGoldTile.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isError = false;
            state.tempGoldTile = payload;
            state.uploadProgress = 0;
        });
        builder.addCase(addAudioToTempGoldTile.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.tempGoldTile = null;
            state.uploadProgress = 0;
        });
    },
});

const { setProgress } = audioSlice.actions;
export default audioSlice.reducer;