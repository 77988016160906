import { iAudioOptions } from "../../interfaces/audioInterfaces";
import { getTempGoldTile } from "../activity/tempGoldTileSlice";
import { AppDispatch } from "../store";
import { addAudioToTempGoldTile } from "./audioSlice";

export const uploadAudioAndRefreshTempGoldTile = (options: iAudioOptions) => async (dispatch: AppDispatch) => {
    try {
        await dispatch(addAudioToTempGoldTile(options)).unwrap();//must unwrap to make error get thrown
        dispatch(getTempGoldTile(options.uniqueUploadId));
    } catch (error) {
        console.log("uploadAudioAndRefreshTempGoldTile failed: ", error);
    }
};