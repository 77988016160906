import { iImageOptions } from "../../interfaces/imageInterfaces";
import { getTempGoldTile } from "../activity/tempGoldTileSlice";
import { AppDispatch } from "../store";
import { addImageToTempGoldTile } from "./imageSlice";

export const uploadImageAndRefreshTempGoldTile = (options: iImageOptions) => async (dispatch: AppDispatch) => {
    try {
        await dispatch(addImageToTempGoldTile(options)).unwrap();//must unwrap to make error get thrown
        dispatch(getTempGoldTile(options.uniqueUploadId));
    } catch (error) {
        console.log("uploadImageAndRefreshTempGoldTile failed: ", error);
    }
};