import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import tempGoldTileSlice from './activity/tempGoldTileSlice';
import imageSlice from './image/imageSlice';
import audioSlice from './audio/audioSlice';
import checkGoldTileTagSlice from './activity/checkGoldTileTagSlice';
import activitySlice from './activity/activitySlice';

// const combinedReducer = combineReducers({
//     token: tokenReducer
// })

// const rootReducer = (state, action) => {
//     return combinedReducer(state, action)
// }

const store = configureStore({
    reducer: {
        tempGoldTile: tempGoldTileSlice,
        image: imageSlice,
        audio: audioSlice,
        checkGoldTileTag: checkGoldTileTagSlice,
        activity: activitySlice,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;