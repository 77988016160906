import { AvTimer } from '@mui/icons-material';
import { Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/sv';
import React, { useEffect, useState } from "react";
import { iLanguage } from '../interfaces/languageInterface';
import languageCache from '../Languages.json';

type Props = {
    startTime: Date;
    chosenLanguageId: number;
};

//Filter out countdown timer to avoid using React.useMemo() => re-renders every second, no need for everything other to do that too
const EditGoldTileCountdownTimer: React.FC<Props> = ({ startTime, chosenLanguageId }) => {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        if (chosenLanguageId) {
            const chosenLanguage: iLanguage = languageCache.find(l => l.languageId === chosenLanguageId)
            moment.locale(chosenLanguage.iso6391);
        }
    }, [chosenLanguageId]);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = moment.utc();
            const duration = moment.duration(moment(startTime).diff(now));

            const formattedTime = `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
            setTimeLeft(formattedTime);

            if (duration.asSeconds() <= 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [startTime]);


    return (
        <>
            <AvTimer sx={{ mt: 1 }} />
            <Typography variant="body1" sx={{ m: 1, color: 'primary.main' }}>
                {timeLeft}
            </Typography>
        </>
    );
};

export default EditGoldTileCountdownTimer;