import { GoldTileType, iActivityImage } from "./interfaces/activityInterfaces";

export const yearAgoAsJson = (): string => {
    let yearAgo = new Date();
    yearAgo.setFullYear(yearAgo.getFullYear() - 1);//returns timestamp
    return new Date(yearAgo).toJSON();
};

export const yearFromNowAsJson = (): string => {
    let yearFromNow = new Date();
    yearFromNow.setFullYear(yearFromNow.getFullYear() + 1);//returns timestamp
    return new Date(yearFromNow).toJSON();
};

export const nowAsJson = (): string => {
    const now = new Date();
    return now.toJSON();
};

export const sameDay = (d1: Date, d2: Date): boolean => {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
};

export const isOld = (dateToCheck: Date): boolean => {
    return dateToCheck.getTime() < Date.now();
};

export const filterInt = (value: string): number => {
    if (/^[-+]?(\d+)$/.test(value)) {
        return Number(value);
    } else {
        return 0;
    }
};

export const formatNumber = (num: string): string => {
    const asInteger = filterInt(num);
    return nFormatter(asInteger, 1);
};

export const nFormatter = (num: number, digits: number): string => {
    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const distFormatter = (num: number): string => {
    let digits = 0;
    if (num < 10000 && num > 1000) {
        digits = 1;
    }
    var si = [
        { value: 1, symbol: " m" },
        { value: 1E3, symbol: " km" },
        { value: 1E6, symbol: " 000 km" },
        { value: 1E9, symbol: " 000 000 km" },
        { value: 1E12, symbol: " T km" },
        { value: 1E15, symbol: " P km" },
        { value: 1E18, symbol: " E km" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i: number;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

//https://www.movable-type.co.uk/scripts/latlong.html
export const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    if (lat1 && lon1 && (lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        const R = 6371e3; // metres
        const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = R * c; // in metres
        return d;
    }
};

export const calculateTotalTimeAndConvertToHumanReadableFormat = (startTime: Date, endTime: Date): string => {
    const totalTimeMilliseconds = endTime.getTime() - startTime.getTime();
    const seconds = Math.floor(totalTimeMilliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - (hours * 3600)) / 60);
    const remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

export const timeConvert = (minutes: number, minutes_text: string, minute_text: string, hours_text: string, hour_text: string): string => {
    if (minutes > 60) {
        var hours = (minutes / 60);
        var rhours = Math.floor(hours);
        var minutesLeft = minutes - (rhours * 60);
        return rhours + ' ' + (rhours !== 1 ? hours_text.toLowerCase() : hour_text.toLowerCase()) + ' ' + minutesLeft + ' ' + (minutesLeft !== 1 ? minutes_text.toLowerCase() : minute_text.toLowerCase());
    } else {
        return minutes + ' ' + (minutes !== 1 ? minutes_text.toLowerCase() : minute_text.toLowerCase());
    }
};

// The maximum is inclusive and the minimum is inclusive
export const getRandomInt = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getLastThreeDigits = (number: number): string => {
    const numAsString = number.toString();
    const lastThreeDigits = numAsString.slice(-3);
    return lastThreeDigits;
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getMonthYearName = (month: number, year: number, locale: string): string => {
    if (month < 1 || month > 12) {
        throw new Error('Invalid month. Month should be between 1 and 12.');
    }

    if (year < 0 || year > 9999) {
        throw new Error('Invalid year. Year should be between 0 and 9999.');
    }

    if (!['en', 'no', 'sv'].includes(locale)) {
        throw new Error('Unsupported locale. Supported locales are "en", "no", and "sv".');
    }

    const date = new Date(year, month - 1, 1);
    return new Intl.DateTimeFormat(locale, { month: 'long', year: 'numeric' }).format(date);
};

export const generateRandomHexColor = () => {
    let color = "#";
    for (let i = 0; i < 3; i++) {
        // Generate a color value between 80 (50 in hex) and 224 (E0 in hex)
        let value = Math.floor(80 + Math.random() * 145).toString(16);
        color += ("0" + value).slice(-2);
    }
    return color;//"black", "red", "blue", "green", "orange", "lightBrown", "brown"
};

export const generateDarkerRandomHexColor = () => {
    let color = "#";
    for (let i = 0; i < 3; i++) {
        // Generate a color value between 0 and 127 (7F in hex)
        let value = Math.floor(Math.random() * 128).toString(16);
        color += ("0" + value).slice(-2);
    }
    return color;
};

export const sortImagesByCoverPhoto = (images: iActivityImage[]): iActivityImage[] => {
    return [...images].sort((a, b) => {
        if (a.coverPhoto && !b.coverPhoto) {
            return -1;  // a comes before b
        }
        if (!a.coverPhoto && b.coverPhoto) {
            return 1;   // b comes before a
        }
        return a.id - b.id;  // sort by id if both have the same coverPhoto status
    });
};

export const isValidCoordinates = (lat: number, lng: number): boolean => {
    const isValidLatitude = lat >= -90 && lat <= 90;
    const isValidLongitude = lng >= -180 && lng <= 180;

    return isValidLatitude && isValidLongitude;
};

export const getGoldTileImageType = (imageGoldTileType: GoldTileType): "microphone" | "image" | "comment-question" | "star-face" | "gift" | "close" => {
    switch (imageGoldTileType) {
        case GoldTileType.audio:
            return "microphone";
        case GoldTileType.image:
            return "image";
        case GoldTileType.quiz:
            return "comment-question";
        case GoldTileType.geocache:
            return "star-face";
        case GoldTileType.gift:
            return "gift";
        default:
            return "close";
    }
};

export const generateControlNumber = (): string => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    let controlNumber = '';

    // Generate first 3 letters
    for (let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * letters.length);
        controlNumber += letters[randomIndex];
    }

    // Generate last 3 numbers
    for (let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * numbers.length);
        controlNumber += numbers[randomIndex];
    }

    return controlNumber;
};

export const roundIfDecimal = (num: number): number | string => {
    if (num % 1 !== 0) { // Check if there are decimals
        return num.toFixed(2); // Round to two decimal places if there are decimals
    }
    return num; // Return the number as is if there are no decimals
};