import { Event, Face, Link, Map, Straighten } from '@mui/icons-material';
import {
    Avatar,
    Box,
    ButtonBase,
    Grid,
    Paper,
    Typography,
    styled
} from "@mui/material";
import { green } from '@mui/material/colors';
import moment from "moment";
import "moment/locale/nb";
import "moment/locale/sv";
import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink } from "react-router-dom";
import '../Letsdo.css';
import languageCache from "../common/Languages.json";
import { blackButtonBackgroundColor, blackColor, cyanColor, lightRedColor, quizYellowColor, redColor, whiteColor } from "../common/colors";
import CustomImageList from '../common/display_elements/WalkImages';
import { getActivityByUniqueId } from '../common/features/activity/activitySlice';
import { AppDispatch, RootState } from '../common/features/store';
import appStoreBadge from '../common/images/App_Store_Badge_US-UK.svg';
import playStoreBadge from '../common/images/play_en_badge_web_generic.png';
import { iActivityImage } from '../common/interfaces/activityInterfaces';
import { iLanguage } from "../common/interfaces/languageInterface";
import { findText } from "../common/textUtil";
import { distFormatter, sortImagesByCoverPhoto } from '../common/utils';

const imageSize = 70;

const sxStyles = {
    root: {
        backgroundColor: blackColor,
        maxWidth: 1000,
        minWidth: 600,
    },
    whiteStyle: {
        color: whiteColor,
    },
    redStyle: {
        color: lightRedColor,
    },
    yellowStyle: {
        color: quizYellowColor,
    },
    cyanStyle: {
        color: cyanColor,
    },
    rightAlign: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "flex-end",
    },
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    // width: 200,
    backgroundColor: redColor
}));

const OpenWalk = ({ chosenLanguageId }) => {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [showAllImages, setShowAllImages] = useState<boolean>(false);
    const useAppDispatch = () => useDispatch<AppDispatch>();
    const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
    const dispatch = useAppDispatch();
    const params = useParams();
    const uniqueActivityId = params.uniqueActivityId;
    const isError = useAppSelector(state => state.activity.isError);
    const activity = useAppSelector(state => state.activity.activity);

    useEffect(() => {
        if (chosenLanguageId) {
            const chosenLanguage: iLanguage = languageCache.find(l => l.languageId === chosenLanguageId);
            moment.locale(chosenLanguage.iso6391);
        }
    }, [chosenLanguageId]);

    useEffect(() => {
        doGetActivity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doGetActivity = async () => {
        await dispatch(getActivityByUniqueId(uniqueActivityId)).unwrap();
        setHasInitialized(true);
    };

    const getText = (textName: string) => {
        return findText(chosenLanguageId, textName);
    };

    const getFormattedDate = (theDate: Date, format: string) => {
        if (theDate) {
            if (format === "fromNow") {
                return moment(theDate).fromNow();
            }
            return moment(theDate).format(format);
        } else {
            return getText("loading_text");
        }
    };

    const start_text = getText("start_text");
    const trail_length = getText("trail_length");
    const location_text = getText("location_text");
    const error_occurred = getText("error_occurred");
    const open_activity_header = getText("open_activity_header");
    const open_activity_info = getText("open_activity_info");
    const install_letsdo_how_to_1 = getText("install_letsdo_how_to_1");
    const install_letsdo_how_to_2 = getText("install_letsdo_how_to_2");
    const install_letsdo_how_to_3 = getText("install_letsdo_how_to_3");
    const go_back = getText("go_back");
    const images: iActivityImage[] = activity && Array.isArray(activity.activityImages) ? activity.activityImages : [];

    const sortedActivityImages = sortImagesByCoverPhoto(images);
    const onlyFirstImage = sortedActivityImages.length > 0 ? [sortedActivityImages[0]] : [];

    return (
        <Grid
            container
            spacing={1}
            // direction="row"
            justifyContent="center"
            alignItems="center"
            sx={sxStyles.root}
        >
            {isError &&
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Item>
                        <Typography variant="body1" >
                            {error_occurred}
                        </Typography>
                    </Item>
                </Grid>
            }
            {hasInitialized &&
                <ButtonBase onClick={() => setShowAllImages(!showAllImages)} style={{ width: '100%' }}>
                    <Grid
                        container
                        spacing={1}
                        // direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={sxStyles.root}
                    >
                        <Grid item xs={12}>
                            <Box sx={{ ml: 1, mr: 1 }}>
                                <CustomImageList sortedActivityImages={showAllImages ? sortedActivityImages : onlyFirstImage} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{ backgroundColor: blackButtonBackgroundColor, borderRadius: 5, p: 2, ml: 1, mr: 1 }}
                            >
                                <Box sx={{ mr: 1 }}>
                                    {activity.accountUniqueImageId ? (
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={process.env.REACT_APP_IMAGE_BROWSER_URL + activity.accountUniqueImageId}
                                        // sx={{ width: 24, height: 24 }}
                                        />
                                    ) : (
                                        <Avatar sx={{ bgcolor: green[500] }}>
                                            <Face />
                                        </Avatar>
                                    )}
                                </Box>
                                <Box>
                                    <Typography variant="body2" sx={sxStyles.cyanStyle}>
                                        {"@" + activity.accountHashtag}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} >
                            <Box
                                sx={{ backgroundColor: blackButtonBackgroundColor, borderRadius: 5, p: 2, ml: 1 }}
                            >
                                <Event />
                                <Typography variant="body2" sx={sxStyles.yellowStyle}>
                                    {start_text}
                                </Typography>
                                <Typography variant="h6" sx={sxStyles.whiteStyle}>
                                    {getFormattedDate(new Date(activity.startTime), "lll")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} >
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{ backgroundColor: blackButtonBackgroundColor, borderRadius: 5, p: 2, mr: 1 }}
                            >
                                <Box>
                                    <img src={process.env.REACT_APP_IMAGE_BROWSER_URL + activity.uniqueImageId} alt="activity" style={{ height: imageSize, width: imageSize }} />
                                </Box>
                                <Box sx={sxStyles.rightAlign}>
                                    <Straighten />
                                    <Typography variant="body2" sx={sxStyles.yellowStyle}>
                                        {trail_length}
                                    </Typography>
                                    <Typography variant="h6" sx={sxStyles.whiteStyle}>
                                        {distFormatter(activity.trailLength)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Box
                                sx={{ backgroundColor: blackButtonBackgroundColor, borderRadius: 5, p: 2, ml: 1, mr: 1 }}
                            >
                                <Map />
                                <Typography variant="body2" sx={sxStyles.yellowStyle}>
                                    {location_text}
                                </Typography>
                                <Typography variant="h6" sx={sxStyles.whiteStyle}>
                                    {activity.city}, {activity.country}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </ButtonBase>
            }
            <Box
                // height={200}
                // width={"80%"}
                // my={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
            // gap={1}
            // p={2}
            >
                <Grid
                    container
                    spacing={2}
                    // direction="row"
                    justifyContent="center"
                    alignItems="center"
                    width={420}
                >
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Typography variant="h5" sx={{ mt: 3, color: 'primary.main' }}>
                            {open_activity_header}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1, textAlign: 'center' }}>
                        <Typography variant="body1" display="inline">
                            {open_activity_info}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 5 }}>
                        <Typography variant="body2">
                            {install_letsdo_how_to_1}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="body2">
                            {install_letsdo_how_to_2}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {install_letsdo_how_to_3}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right" sx={{ pr: 1 }}>
                        <a href='https://play.google.com/store/apps/details?id=com.letsdous.native1'>
                            <img className="Play-logo" alt='Get it on Google Play' src={playStoreBadge} />
                        </a>
                    </Grid>
                    <Grid item xs={6} textAlign="left" >
                        <a href='https://apps.apple.com/us/app/letsdo/id1479329240'>
                            <img className="Appstore-logo" alt='Download on the AppStore' src={appStoreBadge} />
                        </a>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Link to="/" component={RouterLink}>{go_back}</Link>
                    </Grid>
                </Grid>
            </Box >
        </Grid>
    );
};

export default OpenWalk;
