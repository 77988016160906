import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import activityApi from '../../../apis/activityApi';
import { goldTileTagCheckReturn, tempGoldTileTagCheckOptions } from '../../interfaces/activityInterfaces';
import { iThunkApi } from '../../interfaces/reduxInterfaces';


//inputGoldTileTag === isOk -> display name is fine

//All except another's username is OK
export const checkIfTempGoldTileTagIsAllowed = createAsyncThunk<
    goldTileTagCheckReturn, // return type
    tempGoldTileTagCheckOptions, // args type
    iThunkApi // thunkAPI type
>(
    "checkGoldTileTag/checkIfTempGoldTileTagIsAllowed",
    async (inputGoldTileTagOptions, { rejectWithValue }) => {
        try {
            const { data: isOk } = await activityApi.get(`checkIfTempGoldTileTagIsAllowed/${inputGoldTileTagOptions.uniqueUploadId}/${encodeURIComponent(inputGoldTileTagOptions.inputGoldTileTag)}`);
            return { inputGoldTileTag: inputGoldTileTagOptions.inputGoldTileTag, isOk };
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                //alert("Network timeout error!");
                return rejectWithValue("Network timeout error!");
            } else if (error.request) {
                if (error.response) {
                    console.log("error.response: ", error.response);
                }
                //alert("Server refused to process request")
                return rejectWithValue("Server refused to process request");
            } else {
                if (error.response) {
                    console.log("error.response: ", error.response);
                } else {
                    console.log("tempGoldTile/updateTempGoldTile,  error: ", error);
                }
                //alert("Error happened! Unable to upload to the server")
                return rejectWithValue("Error happened! Unable to upload to the server");
            }
        }
    } 
);

const initialState = {
    isLoading: false,
    isError: false,
    inputGoldTileTag: '',
    isOk: false,
};

const checkGoldTileTagSlice = createSlice({
    name: 'checkGoldTileTag',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(checkIfTempGoldTileTagIsAllowed.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(checkIfTempGoldTileTagIsAllowed.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isError = false;
            state.inputGoldTileTag = payload.inputGoldTileTag;
            state.isOk = payload.isOk;
        });
        builder.addCase(checkIfTempGoldTileTagIsAllowed.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
            state.inputGoldTileTag = '';
            state.isOk = false;
        });
    },
});

export default checkGoldTileTagSlice.reducer;