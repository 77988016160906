import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import activityApi from '../../../apis/activityApi';
import { iActivity, iActivitySlice } from '../../interfaces/activityInterfaces';
import { iThunkApi } from '../../interfaces/reduxInterfaces';

// return:
// {
//     "uniqueActivityId": "cd7a740a-7601-4fd3-9794-b3660fbb44e1",
//     "activityType": "IMAGES",
//     "toType": "PRIVATE",
//     "uniqueAccountId": "userid_1",
//     "accountUniqueImageId": null,
//     "accountHashtag": "bjarheim",
//     "accountName": "Peter Bjarheim",
//     "toUniqueAccountId": null,
//     "toUniqueImageId": null,
//     "toName": null,
//     "description": "A time log activity with images",
//     "averageSpeed": 0,
//     "maxSpeed": 0,
//     "minSpeed": 0,
//     "trailLength": 0,
//     "elevationGain": 0,
//     "elevationLoss": 0,
//     "elevationMax": 0,
//     "elevationMin": 0,
//     "likes": 0,
//     "latitude": "59.66858",
//     "longitude": "9.65017",
//     "city": "Kongsberg",
//     "country": "Norge",
//     "isoCountryCode": "no",
//     "uniqueImageId": "7f5ae5db-ff76-4d07-ba5d-5e230e454722",
//     "uniqueVideoId": null,
//     "startTime": "2023-08-27T07:14:17.694+02:00",
//     "endTime": "2023-08-27T07:56:11.694+02:00",
//     "updated": "2023-08-27T11:50:09.043055+02:00",
//     "created": "2023-08-27T11:49:22.132717+02:00",
//     "activityImages": [
//         {
//             "id": 4,
//             "uniqueActivityId": "cd7a740a-7601-4fd3-9794-b3660fbb44e1",
//             "latitude": "59.66858",
//             "longitude": "9.65017",
//             "uniqueImageId": "7f5ae5db-ff76-4d07-ba5d-5e230e454722",
//             "uniqueVideoId": null,
//             "altitude": 102.5,
//             "created": "2023-08-27T11:50:09.043055+02:00"
//         }
//     ]
// }

export const getActivityByUniqueId = createAsyncThunk<
    iActivity, // return type
    string, // args type
    iThunkApi // thunkAPI type
>(
    "activity/getActivityByUniqueId",
    async (uniqueActivityId, { rejectWithValue }) => {
        try {
            const { data: activity } = await activityApi.get(`getActivityByUniqueId/${uniqueActivityId}`);
            return activity;
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                //alert("Network timeout error!");
                return rejectWithValue("Network timeout error!");
            } else if (error.request) {
                if (error.response) {
                    console.log("error.response: ", error.response);
                }
                //alert("Server refused to process request")
                return rejectWithValue("Server refused to process request");
            } else {
                if (error.response) {
                    console.log("error.response: ", error.response);
                } else {
                    console.log("tempGoldTile/getTempGoldTile,  error: ", error);
                }
                //alert("Error happened! Unable to upload to the server")
                return rejectWithValue("Error happened! Unable to upload to the server");
            }
        }
    }
);

const initialState: iActivitySlice = {
    activity: null,
    isLoading: false,
    isError: false,
};

const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getActivityByUniqueId.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(getActivityByUniqueId.fulfilled, (state, { payload }) => {
            state.activity = payload;
            state.isLoading = false;
            state.isError = false;
        });
        builder.addCase(getActivityByUniqueId.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        });
    },
});

export default activitySlice.reducer;