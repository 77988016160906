import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { AxiosError } from 'axios';
import Compressor from 'compressorjs';
import imageApi from '../../../apis/imageApi';
import { iTempGoldTile } from '../../interfaces/activityInterfaces';
import { iImageOptions } from '../../interfaces/imageInterfaces';
import { ValidationErrors, iThunkApi } from '../../interfaces/reduxInterfaces';

export const addImageToTempGoldTile = createAsyncThunk<
    iTempGoldTile, // return type
    iImageOptions, // args type
    iThunkApi // thunkAPI type
>(
    "image/addImageToTempGoldTile",
    async (options, { rejectWithValue, dispatch }) => {
        try {
            const compressedFile = await new Promise<File>((resolve, reject) => {
                new Compressor(options.file, {
                    quality: 0.6,
                    maxWidth: 1280,
                    success(result) {
                        resolve(result as File); // Cast result to File if necessary
                    },
                    error(err) {
                        reject(err);
                    },
                });
            });
            const formData = new FormData();
            formData.append('file', compressedFile, options.file.name);
            const { data: tempGoldTile } = await imageApi.post(`addImageToTempGoldTile/${options.uniqueUploadId}`, formData, {
                onUploadProgress: progressEvent => {
                    dispatch(setProgress(progressEvent.loaded / progressEvent.total));
                },
                headers: { "Content-Type": "multipart/form-data" },
            });
            return tempGoldTile;
        } catch (err) {
            let error: AxiosError<ValidationErrors> = err as AxiosError<ValidationErrors>; // Cast the error for access
            if (error.code === 'ECONNABORTED') {
                return rejectWithValue("Network timeout error!");
            } else if (error.request) {
                return rejectWithValue("Server refused to process request");
            } else {
                if (error.response) {
                    console.log("error.response: ", error.response);
                } else {
                    console.log("image/addImageToTempGoldTile,  error: ", error);
                }
                return rejectWithValue("Error happened! Unable to upload to the server");
            }
        }
    }
);

// Reducer and actions setup remains the same...



//No support for deleting images...

const initialState = {
    isLoading: false,
    isError: false,
    tempGoldTile: null,
    uploadProgress: 0,
};

const imageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        setProgress(state, action) {
            // console.log("action: ", action)
            state.uploadProgress = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addImageToTempGoldTile.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(addImageToTempGoldTile.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isError = false;
            state.tempGoldTile = payload;
            state.uploadProgress = 0;
        });
        builder.addCase(addImageToTempGoldTile.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.tempGoldTile = null;
            state.uploadProgress = 0;
        });
    },
});

const { setProgress } = imageSlice.actions;
export default imageSlice.reducer;