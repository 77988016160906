import { InfoOutlined } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { green, yellow } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { findText } from "../textUtil";

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: green,
        secondary: yellow,
    },
    typography: {
        fontFamily: 'Comic Sans MS, Comic Sans, Marker Felt, cursive',
    },
});

const sxStyles = {
    textField: {
        width: 500,
        // mb: 2,
    },
};

type Props = {
    chosenLanguageId: number;
    descriptionText: string;
    handleSetDescription: (descriptionUpdated: string) => void;
};

const TreasureDescriptionTextInput: React.FC<Props> = ({ chosenLanguageId, descriptionText, handleSetDescription }) => {
    const [description, setDescription] = useState<string>(descriptionText ? descriptionText : '');
    const [descriptionHelpText, setDescriptionHelpText] = useState<string>('');

    const getText = (textName: string): string => {
        return findText(chosenLanguageId, textName);
    };

    const onChange = ({ target: { value } }): void => {
        let fieldValid: boolean = true;
        const cleanedValue: string = value.replace(/\\/g, '');
        if (cleanedValue && cleanedValue.length > 1000) {
            setDescriptionHelpText(getText("text_too_long"));
            fieldValid = false;
        } else if (cleanedValue && cleanedValue.length > 0 && cleanedValue.length < 2) {
            setDescriptionHelpText(getText("text_too_short"));
            fieldValid = false;
        } else {
            setDescriptionHelpText('');
        }
        setDescription(cleanedValue);
        if (fieldValid) {
            handleSetDescription(cleanedValue);
        } else {
            handleSetDescription("");
        }
    };

    const treasure_info_text = getText("treasure_info_text");

    return (
        <ThemeProvider theme={theme}>
            <TextField
                label={treasure_info_text + (description.length > 1 ? ' ' + description.length + '/1000' : '')}
                name="description"
                required
                error={descriptionHelpText !== ''}
                helperText={descriptionHelpText}
                sx={sxStyles.textField}
                value={description}
                onChange={onChange}
                margin="none"
                variant="outlined"
                multiline
                rows="4"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <InfoOutlined />
                        </InputAdornment>
                    ),
                }}
            />
        </ThemeProvider>
    );
};

export default TreasureDescriptionTextInput;