import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import '../Letsdo.css';
import appStoreBadge from '../common/images/App_Store_Badge_US-UK.svg';
import playStoreBadge from '../common/images/play_en_badge_web_generic.png';
import { findText } from '../common/textUtil';

//Not in use anymore
export default function OpenTreasure({ chosenLanguageId }) {
    // const params = useParams();
    // const treasureTag = params.treasureTag;

    const getText = (textName: string) => {
        return findText(chosenLanguageId, textName);
    };

    const treasure_tag_header = getText("treasure_tag_header");
    const treasure_tag_info = getText("treasure_tag_info");
    const install_letsdo_how_to_1 = getText("install_letsdo_how_to_1");
    const install_letsdo_how_to_2 = getText("install_letsdo_how_to_2");
    const install_letsdo_how_to_3 = getText("install_letsdo_how_to_3");
    const go_back = getText("go_back");
    return (
        <Box
            // height={200}
            // width={"80%"}
            // my={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
        // gap={1}
        // p={2}
        >
            <Grid
                container
                spacing={2}
                // direction="row"
                justifyContent="center"
                alignItems="center"
                width={420}
            >
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ mt: 3, color: 'primary.main' }}>
                        {treasure_tag_header}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1, textAlign: 'center' }}>
                    <Typography variant="body1" display="inline">
                        {treasure_tag_info}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 5 }}>
                    <Typography variant="body2">
                        {install_letsdo_how_to_1}
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography variant="body2">
                        {install_letsdo_how_to_2}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        {install_letsdo_how_to_3}
                    </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right" sx={{ pr: 1 }}>
                    <a href='https://play.google.com/store/apps/details?id=com.letsdous.native1'>
                        <img className="Play-logo" alt='Get it on Google Play' src={playStoreBadge} />
                    </a>
                </Grid>
                <Grid item xs={6} textAlign="left" >
                    <a href='https://apps.apple.com/us/app/letsdo/id1479329240'>
                        <img className="Appstore-logo" alt='Download on the AppStore' src={appStoreBadge} />
                    </a>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Link to="/" component={RouterLink}>{go_back}</Link>
                </Grid>
            </Grid>
        </Box >
    );
}
