import { DeleteForever } from '@mui/icons-material';
import { Box, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import letsdoLogo from '../common/images/letsdoLogo.svg';
import { findText } from '../common/textUtil';

//Not in use anymore
export default function DeleteMe({ chosenLanguageId }) {

    const getText = (textName) => {
        return findText(chosenLanguageId, textName)
    }

    const privacy_policy_data_deletion_1 = getText("privacy_policy_data_deletion_1")
    const data_deletion_info = getText("data_deletion_info")
    const privacy_policy_data_deletion_3 = getText("privacy_policy_data_deletion_3")

    const go_back = getText("go_back")
    return (
        <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        // sx={{ border: "1px solid red" }}
        >
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-end"
                alignItems="center"
                sx={{ width: "80%" }}
            >
                <Grid item xs={12} align="center" sx={{ mt: 2, mb: 2 }}>
                    <img src={letsdoLogo} className="Letsdo-logo" alt="logo" />
                </Grid>
                <Grid item sm={12} align="center">
                    <Typography variant="h6" sx={{ m: 1, color: 'primary.main' }}>
                        {privacy_policy_data_deletion_1}
                    </Typography>
                </Grid>
                <Grid item sm={12} align="center">
                    <Typography variant="caption" >
                        {data_deletion_info}
                    </Typography>
                </Grid>
                <Grid item sm={12} align="center">
                    <Typography variant="caption" >
                        {privacy_policy_data_deletion_3}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center">
                    <DeleteForever sx={{ mt: 1, color: 'red', fontSize: 50 }} />
                </Grid>
                <Grid item xs={12} align="center">
                    <Link to="/" component={RouterLink}>{go_back}</Link>
                </Grid>
            </Grid>
        </Box>
    );
}
