import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
// import App from './App';
import { cyan, orange } from '@mui/material/colors';
import { Provider as StoreProvider } from 'react-redux';
import languageCache from './common/Languages.json';
import { letsdoPurpleColor } from './common/colors';
import store from './common/features/store';
import AddMe from './pages/AddMe';
import DeleteMe from './pages/DeleteMe';
import EditTreasure from './pages/EditTreasure';
import OpenTreasure from './pages/OpenTreasure';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ResetPassword from './pages/ResetPassword';
import StartPage from './pages/StartPage';
import TermsOfService from './pages/TermsOfService';
import NoMatch from './pages/components/errors/NoMatch';
import reportWebVitals from './reportWebVitals';
import OpenWalk from './pages/OpenWalk';

// const reload = () => window.location.reload()

let PrimaryLayout = ({ chosenLanguageId }) => (
  <Routes>
    <Route path="/" element={<StartPage chosenLanguageId={chosenLanguageId} />} />
    <Route path="/.well-known/assetlinks.json" />
    <Route path="/.well-known/apple-app-site-association" />
    <Route path="/privacyPolicy" element={<PrivacyPolicy chosenLanguageId={chosenLanguageId} />} />
    <Route path="/termsOfService" element={<TermsOfService chosenLanguageId={chosenLanguageId} />} />
    <Route path="/deleteMe" element={<DeleteMe chosenLanguageId={chosenLanguageId} />} />
    <Route path="/resetPassword/:changePasswordCode" element={<ResetPassword chosenLanguageId={chosenLanguageId} />} />
    <Route path="/addMe/:uniqueAccountId" element={<AddMe chosenLanguageId={chosenLanguageId} />} />
    <Route path="/editTreasure/:uniqueUploadId" element={<EditTreasure chosenLanguageId={chosenLanguageId} />} />
    <Route path="/openTreasures/:treasureTag" element={<OpenTreasure chosenLanguageId={chosenLanguageId} />} />
    <Route path="/openWalk/:uniqueActivityId" element={<OpenWalk chosenLanguageId={chosenLanguageId} />} />
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

interface NavigatorWithUserLanguage extends Navigator {
  userLanguage?: string;
}

const getBrowserLanguageIdIfExists = () => {
  const navigatorExtended = navigator as NavigatorWithUserLanguage;
  let lang = navigator.language || navigatorExtended.userLanguage;
  if (lang) {
    lang = lang.substring(0, 2);
    lang = lang === 'no' ? 'nb' : lang; // Correct moment.js lang
    const browserLanguage = languageCache.find(langObj => langObj.iso6391 === lang);
    if (browserLanguage) {
      //console.log(`Found browser language in languages. Id ${browserLanguage.languageId}`)
      return browserLanguage.languageId;
    }
  }
  return 2;
};


const theme = createTheme({
  // status: {
  //   danger: orange[500],
  // },
  typography: {
    fontFamily: 'Playpen Sans, Roboto',
  },
  palette: {
    mode: 'dark',
    primary: orange,
    secondary: cyan,
    background: {
      default: letsdoPurpleColor
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <PrimaryLayout chosenLanguageId={getBrowserLanguageIdIfExists()} />
        </BrowserRouter>
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
