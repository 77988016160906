import { Lyrics } from '@mui/icons-material';
import { Alert, Grid, IconButton, LinearProgress, Paper, Snackbar, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { orange, yellow } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { redColor } from '../colors';
import { uploadAudioAndRefreshTempGoldTile } from '../features/audio/audioThunk';
import { AppDispatch, RootState } from '../features/store';
import { iAudioOptions } from '../interfaces/audioInterfaces';
import { findText } from '../textUtil';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: orange,
        secondary: yellow,
    },
    typography: {
        fontFamily: 'Comic Sans MS, Comic Sans, Marker Felt, cursive',
    },
});

const sxStyles = {
    audioUploadButton: {
        // marginTop: '40px',
        color: 'lightGreen',
    },
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    // width: 200,
    backgroundColor: redColor
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.success.dark,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.success.dark,
    },
}));

type Props = {
    chosenLanguageId: number;
};

const TreasureAudio: React.FC<Props> = ({ chosenLanguageId }) => {
    const [fileSizeError, setFileSizeError] = useState<string>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const useAppDispatch = () => useDispatch<AppDispatch>();
    const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
    const dispatch = useAppDispatch();
    const tempGoldTile = useAppSelector(state => state.tempGoldTile.tempGoldTile);
    const isError = useAppSelector(state => state.audio.isError);
    const uploadProgress = useAppSelector(state => state.audio.uploadProgress);

    const getText = (textName: string) => {
        return findText(chosenLanguageId, textName);
    };


    // Handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Get the file from the event target, ensuring the files list is not null or undefined
        const file = event.target.files ? event.target.files[0] : null;
        const maxSize = 2 * 1024 * 1024; // 2 MB in bytes

        if (file && file.size > maxSize) {
            setFileSizeError(getText("error_file_size"));
            setTimeout(() => setFileSizeError(null), 6000);
            event.target.value = null; // Reset the input to allow re-selection of file
        } else if (file) {
            const options: iAudioOptions = {
                file: file,
                uniqueUploadId: tempGoldTile.uniqueUploadId,
            };
            dispatch(uploadAudioAndRefreshTempGoldTile(options));
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current?.click(); // Programmatically click the file input
    };

    const handleClose = () => {
        setFileSizeError(null);
    };

    const error_occurred = getText("error_occurred");
    const select_file_info = getText("select_file_info");

    return (
        <ThemeProvider theme={theme}>
            <input
                type="file"
                accept="audio/mpeg,audio/x-m4a"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            <Grid
                container
                spacing={2}
                // direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {isError &&
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Item>
                            <Typography variant="body1" >
                                {error_occurred}
                            </Typography>
                        </Item>
                    </Grid>
                }
                {uploadProgress > 0 &&
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <LinearProgress variant="determinate" value={uploadProgress} />
                    </Grid>
                }
            </Grid>
            {tempGoldTile &&
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ pl: tempGoldTile.uniqueAudioId ? 11 : 0 }}>
                        <CustomTooltip title={select_file_info} >
                            <IconButton
                                sx={sxStyles.audioUploadButton}
                                onClick={handleButtonClick}
                            >
                                <Lyrics sx={{ fontSize: '100px' }} />
                            </IconButton>
                        </CustomTooltip>
                    </Grid>
                    {tempGoldTile.uniqueAudioId &&
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                            <audio src={process.env.REACT_APP_AUDIO_BROWSER_URL + tempGoldTile.uniqueAudioId} controls />
                        </Grid>
                    }

                </Grid>
            }
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={fileSizeError ? true : false}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {fileSizeError}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default TreasureAudio;