export const letsdoStrokeColor = "#ff0"
export const letsdoLogoColor = "#507255"
export const letsdoPurpleColor = "#330066"
export const letsdoOrangeColor = "#ff9933"
export const letsdoPinkColor = "#cc00ff"
export const letsdoDarkColor = "#3f2370"//legacy
export const letsdoColor = "#673ab7"//lecacy
export const letsdoYellowColor = '#ffeb3b'//legacy
export const purpleColor = "purple"
export const deepPurpleColor = "#36013F"
export const ligthPurpleColor = "#cc00cc"
export const violetColor = "#A32EFF"
export const violetLightColor = "#d399ff"
export const yellowColor = 'yellow'
export const yellowVarianColor = '#ffeb3b'
export const secondaryDarkColor = '#fae000'
export const greyColor = 'grey'
export const inactiveDarkModeColor = '#a6a6a6'
export const backgroundColor = '#f6f6f6'
export const redColor = 'red'
export const lightRedColor = '#ffb3d7'
export const blinkHearthColor = '#b30000'
export const whiteColor = 'white'
export const blackColor = 'black'
export const greenColor = 'green'
export const lightGreenColor = 'lightgreen'
export const darkGreenColor = 'darkgreen'
export const darkBlueColor = 'darkblue'
export const blueColor = 'blue'
export const ligthBlueColor = '#4d4dff'
export const lastDayOfStreakColor = '#FF69B4'
export const streakDarkColor = 'greenyellow'
export const lastDayOfStreakDarkColor = 'palegreen'
export const streakLightColor = 'skyblue'
export const lastDayOfStreakLightColor = 'lightgrey'
export const darkOrangeColor = '#ff8c00'
export const chocolateColor = 'chocolate'
export const faceColorLight = 'moccasin'
export const cookieColor = '#cd853f'
export const flowerColor = '#fc7ef4'
export const saddlebrownColor = 'saddlebrown'
export const lightBrownColor = '#ffa64d'
export const lightGreyColor = 'lightgrey'
export const greyBlackColor = "#595959"
export const navigationTabsColor = "#2c2830"
export const cyanColor = "cyan"
export const cyanDarkColor = "#006f6f"
export const orangeColor = "orange"
export const pinkColor = "pink"
export const darkPinkColor = "#ff6680"
export const eggColor = "#fcfbe6"
export const eggBlue = "#00a4bd"
export const hotPinkColor = "#FF69B4"
export const neonBlueColor = "#00FFFF"
export const chatPinkColor = "#c586c0"
export const chatCyanColor = "#7bcdc4"
export const chatYellowColor = "#dcdc8b"
export const chatGreenColor = "#6a9955"
export const chatBackgroundColor = "#1e1e1e"
export const blackButtonBackgroundColor = "#1a1a1a"
export const quizYellowColor = "#ffc906"
