import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { iThunkApi } from '../../interfaces/reduxInterfaces';
import { iTempGoldTile, iTempGoldTileSlice } from '../../interfaces/activityInterfaces';
import activityApi from '../../../apis/activityApi';

// return:
// {
//     "id": null,
//     "uniqueUploadId": "3ff42d7a-1007-4713-bbeb-f98db76d4c1c",
//     "goldTileType": "IMAGE",
//     "uniqueAccountId": "userid_1",
//     "accountUniqueImageId": null,
//     "accountHashtag": "bjarheim",
//     "accountName": "Peter Bjarheim",
//     "uniqueImageId": null,
//     "uniqueAudioId": null,
//     "title": null,
//     "description": null,
//     "goldTileTag": null,
//     "deleteAt": "2024-02-09T16:10:45.1916867Z",
//     "updated": "2024-02-09T15:10:45.1916867Z",
//     "created": "2024-02-09T15:10:45.1916867Z"
// }

//Should only update the image or description
export const updateTempGoldTile = createAsyncThunk<
    iTempGoldTile, // return type
    Partial<iTempGoldTile>, // args type
    iThunkApi // thunkAPI type
>(
    "tempGoldTile/updateTempGoldTile",
    async (updatedTempGoldTile, { rejectWithValue }) => {
        try {
            const { data: tempGoldTile } = await activityApi.put(`updateTempGoldTile`, updatedTempGoldTile);
            // console.log("tempGoldTile: ", tempGoldTile)
            return tempGoldTile;
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                //alert("Network timeout error!");
                return rejectWithValue("Network timeout error!");
            } else if (error.request) {
                if (error.response) {
                    console.log("error.response: ", error.response);
                }
                //alert("Server refused to process request")
                return rejectWithValue("Server refused to process request");
            } else {
                if (error.response) {
                    console.log("error.response: ", error.response);
                } else {
                    console.log("tempGoldTile/updateTempGoldTile,  error: ", error);
                }
                //alert("Error happened! Unable to upload to the server")
                return rejectWithValue("Error happened! Unable to upload to the server");
            }
        }
    }
);

//No auth needed
export const getTempGoldTile = createAsyncThunk<
    iTempGoldTile, // return type
    string, // args type
    iThunkApi // thunkAPI type
>(
    "tempGoldTile/getTempGoldTile",
    async (uniqueUploadId, { rejectWithValue }) => {
        try {
            const { data: tempGoldTile } = await activityApi.get(`getTempGoldTile/${uniqueUploadId}`);
            // console.log("tempGoldTile: ", tempGoldTile)
            return tempGoldTile;
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                //alert("Network timeout error!");
                return rejectWithValue("Network timeout error!");
            } else if (error.request) {
                if (error.response) {
                    console.log("error.response: ", error.response);
                }
                //alert("Server refused to process request")
                return rejectWithValue("Server refused to process request");
            } else {
                if (error.response) {
                    console.log("error.response: ", error.response);
                } else {
                    console.log("tempGoldTile/getTempGoldTile,  error: ", error);
                }
                //alert("Error happened! Unable to upload to the server")
                return rejectWithValue("Error happened! Unable to upload to the server");
            }
        }
    }
);


const initialState: iTempGoldTileSlice = {
    isLoading: false,
    hasFailed: false,
    tempGoldTile: null,
};

const tempGoldTileSlice = createSlice({
    name: 'tempGoldTile',
    initialState,
    reducers: {
        resetGoldTile(state) {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTempGoldTile.pending, (state) => {
            state.isLoading = true;
            state.hasFailed = false;
        });
        builder.addCase(getTempGoldTile.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.hasFailed = false;
            state.tempGoldTile = payload;
        });
        builder.addCase(getTempGoldTile.rejected, (state) => {
            state.isLoading = false;
            state.hasFailed = true;
            state.tempGoldTile = null;
        });
        builder.addCase(updateTempGoldTile.pending, (state) => {
            state.isLoading = true;
            state.hasFailed = false;
        });
        builder.addCase(updateTempGoldTile.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.hasFailed = false;
            state.tempGoldTile = payload;
        });
        builder.addCase(updateTempGoldTile.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.hasFailed = true;
            state.tempGoldTile = null;
        });
    },
});

export const { resetGoldTile } = tempGoldTileSlice.actions;
export default tempGoldTileSlice.reducer;