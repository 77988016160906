import commonTextCacheEnglish from './CommonText_en.json'
import commonTextCacheNorwegian from './CommonText_nb.json'
import commonTextCacheSwedish from './CommonText_sv.json'

export const findText = (languageId, textName) => {
    //console.log(JSON.stringify(textCache))
    let textFound = findCommonText(languageId, textName)
    if (textFound) {
        return textFound
    }
    return "Text missing"
}

export const findCommonText = (languageId, textName) => {
    //console.log(JSON.stringify(textCache))
    let textObjectFound = null
    if (languageId === 1) {
        textObjectFound = commonTextCacheNorwegian.find(t => t.textName === textName)    
    } else if (languageId === 2) {
        textObjectFound = commonTextCacheEnglish.find(t => t.textName === textName)    
    } else if (languageId === 3) {
        textObjectFound = commonTextCacheSwedish.find(t => t.textName === textName)    
    }

    if (textObjectFound) {
        return textObjectFound.text
    }
    return ''
}

export const characterLimit = (text, limit) => text.length > limit ? (text.replace(/(\r\n|\n|\r)/gm,' -> ').trim().substr(0, limit - 1) + '...') : text.replace(/(\r\n|\n|\r)/gm,' -> ').trim()
export const characterMaxLength = (text, limit) => text.length > limit ? (text.replace(/(\r\n|\n|\r)/gm,' -> ').trim().substr(0, limit - 4) + '...') : text.replace(/(\r\n|\n|\r)/gm,' -> ').trim()