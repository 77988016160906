
import { EditLocationOutlined } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { cyan, orange } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { findText } from "../textUtil";

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: cyan,
        secondary: orange,
    },
    typography: {
        fontFamily: 'Comic Sans MS, Comic Sans, Marker Felt, cursive',
    },
});

const sxStyles = {
    textField: {
        width: 500,
        // mb: 2,
    },
};

type Props = {
    questionText: string;
    handleSetQuestion: (questionUpdated: string) => void;
    autoFocus: boolean;
    chosenLanguageId: number;
    // handleFinished: () => void;
};

const TreasureQuizQuestionTextInput: React.FC<Props> = ({ questionText, handleSetQuestion, autoFocus = false, chosenLanguageId }) => {
    const [question, setQuestion] = useState<string>(questionText ? questionText : '');
    const [questionHelpText, setQuestionHelpText] = useState<string>('');

    const getText = (textName: string): string => {
        return findText(chosenLanguageId, textName);
    };

    const onChange = ({ target: { value } }): void => {
        let fieldValid: boolean = true;
        const cleanedValue: string = value.replace(/\\/g, '');
        if (cleanedValue && cleanedValue.length > 100) {
            setQuestionHelpText(getText("text_too_long"));
            fieldValid = false;
        } else if (cleanedValue && cleanedValue.length > 0 && cleanedValue.length < 2) {
            setQuestionHelpText(getText("text_too_short"));
            fieldValid = false;
        } else {
            setQuestionHelpText('');
        }
        setQuestion(cleanedValue);
        if (fieldValid) {
            handleSetQuestion(cleanedValue);
        } else {
            handleSetQuestion("");
        }
    };

    const question_text = getText("question_text");

    return (
        <ThemeProvider theme={theme}>
            <TextField
                label={question_text + (question.length > 1 ? ' ' + question.length + '/100' : '')}
                name="question"
                required
                error={questionHelpText !== ''}
                helperText={questionHelpText}
                sx={sxStyles.textField}
                value={question}
                onChange={onChange}
                margin="none"
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EditLocationOutlined />
                        </InputAdornment>
                    ),
                }}
            />
        </ThemeProvider>
    );
};

export default TreasureQuizQuestionTextInput;