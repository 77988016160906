import { Box, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import YouTube from "react-youtube";
import '../Letsdo.css';
import { greyColor, letsdoOrangeColor, letsdoPinkColor, whiteColor, yellowColor } from '../common/colors';
import appStoreBadge from '../common/images/App_Store_Badge_US-UK.svg';
import letsdoLogo from '../common/images/letsdoLogo.svg';
import playStoreBadge from '../common/images/play_en_badge_web_generic.png';
import { findText } from '../common/textUtil';

export default function StartPage({ chosenLanguageId }) {

    const getText = (textName) => {
        return findText(chosenLanguageId, textName)
    }

    const options = {
        height: '500',
        width: '400',
        playerVars: {
            autoplay: 1,
            // controls: 1,
        },
    };

    const _onReady = (event) => {
        event.target.pauseVideo();
    }

    const letsdo_info_1 = getText("letsdo_info_1")
    const letsdo_info_2 = getText("letsdo_info_2")
    const signup_terms = getText("signup_terms")
    const privacy_policy = getText("privacy_policy")
    const email_text = getText("email_text")
    const slogan_title = getText("slogan_title")
    const slogan_why_1 = getText("slogan_why_1")
    const slogan_why_2 = getText("slogan_why_2")
    const slogan_why_3 = getText("slogan_why_3")
    const slogan_why_4 = getText("slogan_why_4")
    const slogan_why_5 = getText("slogan_why_5")

    return (
        <Box
            height="200vh"
            display="flex"
            flexDirection="column"
            justify="center"
            alignItems="center"
        // sx={{ border: "1px solid red" }}
        >
            <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
                sx={{ mt: 2, maxWidth: 1200 }}
            >
                <Grid item xs={12} align="center">
                    <Typography variant="h3" sx={{ color: letsdoOrangeColor, fontFamily: 'Roboto, Playpen Sans', }}>
                        {letsdo_info_1}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ mt: 10, mb: 10 }}>
                    <img src={letsdoLogo} className="Letsdo-logo" alt="logo" />
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography variant="body2" sx={{ color: letsdoPinkColor }}>
                        {letsdo_info_2}
                    </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right" sx={{ pr: 1 }}>
                    <a href='https://play.google.com/store/apps/details?id=com.letsdous.native1'>
                        <img className="Play-logo" alt='Get it on Google Play' src={playStoreBadge} />
                    </a>
                </Grid>
                <Grid item xs={6} textAlign="left" >
                    <a href='https://apps.apple.com/us/app/letsdo/id1479329240'>
                        <img className="Appstore-logo" alt='Download on the AppStore' src={appStoreBadge} />
                    </a>
                </Grid>
                <Grid item xs={6} textAlign="right" sx={{ pr: 2 }}>
                    <Link to="termsOfService" component={RouterLink}>{signup_terms}</Link>
                </Grid>
                <Grid item xs={6} textAlign="left">
                    <Link to="privacyPolicy" component={RouterLink}>{privacy_policy}</Link>
                </Grid>
                <Grid item xs={12} align="center" sx={{ mt: 6, ml: 1, mr: 1 }}>
                    <Typography variant="body1" sx={{ color: yellowColor }}>
                        {slogan_title}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ mt: 0 }}>
                    <Typography variant="caption" sx={{ color: whiteColor }}>
                        {slogan_why_2}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ mt: 3 }}>
                    <Typography variant="body1" sx={{ color: yellowColor }}>
                        {slogan_why_1}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" >
                    <Typography variant="caption" sx={{ color: whiteColor }}>
                        {slogan_why_3}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ mt: 3 }}>
                    <Typography variant="body1" sx={{ color: yellowColor }}>
                        {slogan_why_4}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography variant="caption" sx={{ color: whiteColor }}>
                        {slogan_why_5}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ mt: 4 }}>
                    <YouTube videoId="r6icI0NUhik" opts={options} onReady={_onReady} id="video" />
                </Grid>
                <Grid item xs={12} align="center" >
                    <Typography variant="caption" sx={{ color: greyColor }}>
                        {email_text}: support@letsdo.app
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}