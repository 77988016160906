import { AddPhotoAlternate } from '@mui/icons-material';
import { ButtonBase, Grid, IconButton, LinearProgress, Paper, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { orange, yellow } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { redColor } from '../colors';
import { uploadImageAndRefreshTempGoldTile } from '../features/image/imageThunk';
import { AppDispatch, RootState } from '../features/store';
import { iImageOptions } from '../interfaces/imageInterfaces';
import { findText } from '../textUtil';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: orange,
        secondary: yellow,
    },
    typography: {
        fontFamily: 'Comic Sans MS, Comic Sans, Marker Felt, cursive',
    },
});

const sxStyles = {
    photoUploadButton: {
        // marginTop: '40px',
        color: "orange",
    },
    photoIcon: {
        fontSize: '100px',
    },
};

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.black,
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "orange",
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    // width: 200,
    backgroundColor: redColor
}));

type Props = {
    chosenLanguageId: number;
};

const TreasureImage: React.FC<Props> = ({ chosenLanguageId }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const useAppDispatch = () => useDispatch<AppDispatch>();
    const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
    const dispatch = useAppDispatch();
    const tempGoldTile = useAppSelector(state => state.tempGoldTile.tempGoldTile);
    const isError = useAppSelector(state => state.image.isError);
    const uploadProgress = useAppSelector(state => state.image.uploadProgress);

    const getText = (textName: string) => {
        return findText(chosenLanguageId, textName);
    };


    // Handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Get the file from the event target, ensuring the files list is not null or undefined
        const file = event.target.files ? event.target.files[0] : null;
        const options: iImageOptions = {
            file: file,
            uniqueUploadId: tempGoldTile.uniqueUploadId,
        };
        dispatch(uploadImageAndRefreshTempGoldTile(options));
    };

    const handleButtonClick = () => {
        fileInputRef.current?.click(); // Programmatically click the file input
    };

    const error_occurred = getText("error_occurred");
    const select_file_info = getText("select_file_info");

    return (
        <ThemeProvider theme={theme}>
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            <Grid
                container
                spacing={2}
                // direction="row"
                justifyContent="center"
                alignItems="center"
            // sx={{ border: "1px solid red" }}
            >
                {isError &&
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Item>
                            <Typography variant="body1" >
                                {error_occurred}
                            </Typography>
                        </Item>
                    </Grid>
                }
                {uploadProgress > 0 &&
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <LinearProgress variant="determinate" value={uploadProgress} />
                    </Grid>
                }
            </Grid>
            {tempGoldTile &&
                <Grid container spacing={0}>
                    {tempGoldTile.uniqueImageId ?
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                            <CustomTooltip title={select_file_info} >
                                <ImageButton
                                    style={{
                                        width: 300,
                                        height: 300,
                                    }}
                                    onClick={handleButtonClick}
                                >
                                    <ImageSrc style={{ borderRadius: 10, backgroundImage: `url(${process.env.REACT_APP_IMAGE_BROWSER_URL + tempGoldTile.uniqueImageId})` }} />
                                    <ImageBackdrop className="MuiImageBackdrop-root" />
                                    <Image>
                                        <AddPhotoAlternate sx={sxStyles.photoIcon} />
                                    </Image>
                                </ImageButton>
                            </CustomTooltip>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <CustomTooltip title={select_file_info} >
                                <IconButton
                                    sx={sxStyles.photoUploadButton}
                                    onClick={handleButtonClick}
                                >
                                    <AddPhotoAlternate sx={sxStyles.photoIcon} />
                                </IconButton>
                            </CustomTooltip>
                        </Grid>
                    }
                </Grid>
            }
        </ThemeProvider>
    );
};

export default TreasureImage;