
import { Save } from '@mui/icons-material';
import { Alert, Box, Button, Grid, Paper, Snackbar, ThemeProvider, Tooltip, TooltipProps, Typography, createTheme, styled, tooltipClasses } from '@mui/material';
import { orange, yellow } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import '../Letsdo.css';
import { redColor } from '../common/colors';
import MultipleChoiceAnswers from '../common/complex_input/MultipleChoiceAnswers';
import EditGoldTileCountdownTimer from '../common/display_elements/EditGoldTileCountdownTimer';
import TreasureAudio from '../common/display_elements/TreasureAudio';
import TreasureDescriptionTextInput from '../common/display_elements/TreasureDescriptionTextInput';
import TreasureImage from '../common/display_elements/TreasureImage';
import TreasureTagTextInput from '../common/display_elements/TreasureTagTextInput';
import TreasureTitleTextInput from '../common/display_elements/TreasureTitleTextInput';
import { getTempGoldTile, updateTempGoldTile } from '../common/features/activity/tempGoldTileSlice';
import { AppDispatch, RootState } from '../common/features/store';
import { GoldTileType, iAnswer, iTempGoldTile } from '../common/interfaces/activityInterfaces';
import { findText } from '../common/textUtil';
import TreasureQuizQuestionTextInput from '../common/text_input/TreasureQuizQuestionTextInput';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: orange,
        secondary: yellow,
    },
    typography: {
        fontFamily: 'Comic Sans MS, Comic Sans, Marker Felt, cursive',
    },
});

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    // width: 200,
    backgroundColor: redColor
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.secondary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.black,
    },
}));

type GoldTileUpdates = {
    title?: string,
    description?: string,
    goldTileTag?: string,
    jsonData?: string | null,
};

export default function EditTreasure({ chosenLanguageId }) {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [showSaved, setShowSaved] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [goldTileTag, setGoldTileTag] = useState<string>('');
    const [question, setQuestion] = useState<string>("");
    const [answers, setAnswers] = useState<iAnswer[]>([]);
    const useAppDispatch = () => useDispatch<AppDispatch>();
    const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
    const dispatch = useAppDispatch();
    const params = useParams();
    const uniqueUploadId = params.uniqueUploadId;
    const hasFailed = useAppSelector(state => state.tempGoldTile.hasFailed);
    const tempGoldTile = useAppSelector(state => state.tempGoldTile.tempGoldTile);

    useEffect(() => {
        doGetTempGoldTile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doGetTempGoldTile = async () => {
        const updatedGoldtile = await dispatch(getTempGoldTile(uniqueUploadId)).unwrap();
        if (updatedGoldtile) {
            setTitle(updatedGoldtile.title || '');
            setDescription(updatedGoldtile.description || '');
            setGoldTileTag(updatedGoldtile.goldTileTag || '');
            const questionWithAnswers = updatedGoldtile && updatedGoldtile.jsonData ? JSON.parse(updatedGoldtile.jsonData) : null;
            if (questionWithAnswers) {
                setQuestion(questionWithAnswers.question);
                setAnswers(questionWithAnswers.answers);
            }
        }
        setHasInitialized(true);
    };

    const getText = (textName: string) => {
        return findText(chosenLanguageId, textName);
    };

    const handleSetTitle = (titleUpdated: string) => {
        setTitle(titleUpdated);
    };

    const handleSetDescription = (descriptionUpdated: string) => {
        setDescription(descriptionUpdated);
    };

    const handleSetGoldTileTag = (goldTileTagUpdated: string) => {
        setGoldTileTag(goldTileTagUpdated);
    };

    const handleUpload = () => {
        const updates: GoldTileUpdates = {};

        if (title !== '') {
            updates.title = title;
        }
        if (description !== '') {
            updates.description = description;
        }
        if (goldTileTag !== '') {
            updates.goldTileTag = goldTileTag;
        }
        if (question !== '' && answers.length > 1) {
            updates.jsonData = JSON.stringify({ question, answers });
        }

        // Create an updated object that merges existing tempGoldTile properties
        // with any updates, converting empty strings to null where necessary
        const updatedTempGoldTile: iTempGoldTile = {
            ...tempGoldTile,
            ...updates,
            title: updates.title ?? tempGoldTile.title,
            description: updates.description ?? tempGoldTile.description,
            goldTileTag: updates.goldTileTag ?? tempGoldTile.goldTileTag,
            jsonData: updates.jsonData ?? tempGoldTile.jsonData,
        };
        dispatch(updateTempGoldTile(updatedTempGoldTile));
        setShowSaved(true);
        setTimeout(() => setShowSaved(false), 6000);
    };

    const handleSetAnswers = (updatedMultipleChoiceAnswers: iAnswer[]): void => {
        setAnswers(updatedMultipleChoiceAnswers);
    };

    const handleSetQuestion = (updatedQuestion: string) => {
        setQuestion(updatedQuestion);
    };

    const handleClose = () => {
        setShowSaved(false);
    };

    const save_text = getText("save_text");
    const saved_text = getText("saved_text");
    const edit_treasure = getText("edit_treasure");
    const error_occurred = getText("error_occurred");
    const upload_text_info = getText("upload_text_info");
    const hasAudio = tempGoldTile && tempGoldTile.goldTileType === GoldTileType.audio ? true : false;

    return (
        <ThemeProvider theme={theme}>
            <Box
                // height="100vh"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
            // sx={{ border: "1px solid red" }}
            >
                <Grid
                    container
                    spacing={2}
                    // direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ m: 1, color: 'primary.main' }}>
                            {edit_treasure}
                        </Typography>
                    </Grid>
                    {hasFailed &&
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Item>
                                <Typography variant="body1" >
                                    {error_occurred}
                                </Typography>
                            </Item>
                        </Grid>
                    }
                    {hasInitialized &&
                        <>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <EditGoldTileCountdownTimer
                                    startTime={new Date(tempGoldTile.deleteAt)}
                                    chosenLanguageId={chosenLanguageId}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <TreasureTitleTextInput
                                    chosenLanguageId={chosenLanguageId}
                                    titleText={title}
                                    handleSetTitle={handleSetTitle}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <TreasureDescriptionTextInput
                                    chosenLanguageId={chosenLanguageId}
                                    descriptionText={description}
                                    handleSetDescription={handleSetDescription}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <TreasureTagTextInput
                                    chosenLanguageId={chosenLanguageId}
                                    uniqueUploadId={uniqueUploadId}
                                    goldTileTagText={goldTileTag}
                                    handleSetGoldTileTag={handleSetGoldTileTag}
                                />
                            </Grid>
                            {tempGoldTile.goldTileType === GoldTileType.quiz &&
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <TreasureQuizQuestionTextInput questionText={question} handleSetQuestion={handleSetQuestion} autoFocus={false} chosenLanguageId={chosenLanguageId} />
                                    <MultipleChoiceAnswers handleSetMultipleChoiceAnswers={handleSetAnswers} multipleChoiceAnswers={answers} chosenLanguageId={chosenLanguageId} />
                                </Grid>
                            }
                            <Grid item xs={12} style={{ textAlign: 'center' }} sx={{ mt: 2 }}>
                                <CustomTooltip title={upload_text_info} >
                                    <Button
                                        onClick={() => handleUpload()}
                                        variant="contained"
                                        color="secondary"
                                        size='large'
                                    >
                                        <Save sx={{ mr: 1 }} />
                                        {save_text}
                                    </Button>
                                </CustomTooltip>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }} sx={{ mt: 2 }} />
                            {hasAudio ?
                                <>
                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                        <TreasureImage chosenLanguageId={chosenLanguageId} />
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                                        <TreasureAudio chosenLanguageId={chosenLanguageId} />
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <TreasureImage chosenLanguageId={chosenLanguageId} />
                                </Grid>
                            }
                            <Grid item xs={12} style={{ textAlign: 'center' }} sx={{ mb: 100 }} />
                        </>
                    }
                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={showSaved ? true : false}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {saved_text}
                    </Alert>
                </Snackbar>
            </Box>
        </ThemeProvider>
    );
}
