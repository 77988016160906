import { Add, Close, ThumbDown, ThumbUp } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { cyan, orange } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { greenColor, lightGreenColor, redColor } from '../colors';
import { iAnswer } from '../interfaces/activityInterfaces';
import { findText } from '../textUtil';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: cyan,
        secondary: orange,
    },
    typography: {
        fontFamily: 'Comic Sans MS, Comic Sans, Marker Felt, cursive',
    },
});

const sxStyles = {
    textField: {
        width: 400,
        // mb: 2,
    },
};

type Props = {
    handleSetMultipleChoiceAnswers: (answer: Array<iAnswer>) => void;
    multipleChoiceAnswers: Array<iAnswer>;
    chosenLanguageId: number;
};

const MultipleChoiceAnswers: React.FC<Props> = ({ handleSetMultipleChoiceAnswers, multipleChoiceAnswers, chosenLanguageId }) => {
    const [answers, setAnswers] = useState<iAnswer[]>([]);

    useEffect(() => {
        if (Array.isArray(multipleChoiceAnswers) && multipleChoiceAnswers.length > 0) {
            setAnswers(multipleChoiceAnswers);
        } else {
            setAnswers([{ answer: '', correct: false, helpText: '', selected: false }]);//selected is for use when user answers the question (copy from question to response and modify)
            handleSetMultipleChoiceAnswers([]);//will disable submit
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getText = (textName: string): string => {
        return findText(chosenLanguageId, textName);
    };

    const addAnswer = (): void => {
        setAnswers([...answers, { answer: '', correct: false, helpText: '', selected: false }]);//selected is for use when user answers the question (copy from question to response and modify)
        handleSetMultipleChoiceAnswers([]);//user must enter an answer
    };

    const removeAnswer = (index: number): void => {
        const newAnswers = [...answers];
        newAnswers.splice(index, 1);
        const hasError = newAnswers.find(a => a.helpText !== '' || a.answer === '');
        setAnswers(newAnswers);
        if (hasError) {
            handleSetMultipleChoiceAnswers([]);//user must enter an answer
        } else {
            handleSetMultipleChoiceAnswers(newAnswers);
        }
    };

    const handleAnswerChange = ({ target: { value, name } }): void => {
        const cleanedValue = value.replace(/\\/g, '');
        const newAnswers = [...answers];
        let answerHelpText = '';
        if (cleanedValue && cleanedValue.length > 60) {
            answerHelpText = getText("text_too_long");
        } else if (cleanedValue.length < 1) {
            answerHelpText = getText("text_too_short");
        }
        const index = parseInt(name.split('_')[1]);
        newAnswers[index] = {
            ...newAnswers[index],
            answer: cleanedValue,
            helpText: answerHelpText
        };
        // console.log("newAnswers: ", newAnswers);
        setAnswers(newAnswers);
        if (answerHelpText === '') {
            handleSetMultipleChoiceAnswers(newAnswers);
        } else {
            handleSetMultipleChoiceAnswers([]);
        }
    };

    const handleCheckboxChange = (index: number): void => {
        const newAnswers = [...answers];
        newAnswers[index] = {
            ...newAnswers[index],
            correct: !newAnswers[index].correct
        };
        setAnswers(newAnswers);
        if (newAnswers[index].helpText === '') {
            handleSetMultipleChoiceAnswers(newAnswers);
        } else {
            handleSetMultipleChoiceAnswers([]);
        }
    };

    const answer_text = getText("answer_text");
    const enter_your_answer = getText("enter_your_answer");
    const error_quiz_question_and_answers = getText("error_quiz_question_and_answers");
    const showWarning = answers.length < 2 || (answers.length > 0 && answers[answers.length - 1].answer.length < 1);

    return (
        <Box gap={1} p={2}>
            <Stack spacing={2}>
                {answers.map((answer, index) => {
                    // console.log("Map index: ", index)
                    // console.log("Map answer: ", answer)
                    return (
                        <Box
                            key={index}
                            // height={200}
                            // width={200}
                            // my={4}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="row"
                        // gap={1}
                        // p={2}
                        >

                            {index === 0 ?
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <IconButton size="large" onClick={addAnswer} sx={{ color: lightGreenColor }}>
                                        <Add fontSize="inherit" />
                                    </IconButton>
                                </Box>
                                :
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <IconButton size="large" onClick={() => removeAnswer(index)} sx={{ color: redColor }}>
                                        <Close fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            }
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <ThemeProvider theme={theme}>
                                    <TextField
                                        placeholder={enter_your_answer}
                                        label={answer_text + ' ' + (index + 1) + ((answer.answer && answer.answer.length > 1) ? ' (' + answer.answer.length + '/60)' : '')}
                                        name={"answer_" + index}
                                        required
                                        error={answer.helpText !== ''}
                                        helperText={answer.helpText}
                                        sx={sxStyles.textField}
                                        value={answer.answer}
                                        onChange={handleAnswerChange}
                                        margin="none"
                                        variant="outlined"
                                    />
                                </ThemeProvider>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                {answer.correct ?
                                    <IconButton size="large" onClick={() => handleCheckboxChange(index)} sx={{ color: greenColor }}>
                                        <ThumbUp fontSize="inherit" />
                                    </IconButton>
                                    :
                                    <IconButton size="large" onClick={() => handleCheckboxChange(index)} sx={{ color: redColor }}>
                                        <ThumbDown fontSize="inherit" />
                                    </IconButton>
                                }
                            </Box>
                        </Box>
                    );
                })}
                {showWarning ?
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="caption" color="error">
                            {error_quiz_question_and_answers}
                        </Typography>
                    </Box>
                    :
                    null
                }
            </Stack>
        </Box>
    );
};

export default MultipleChoiceAnswers;
