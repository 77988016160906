import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import * as React from 'react';
import { iActivityImage } from '../interfaces/activityInterfaces';
// import IconButton from '@mui/material/IconButton';
// import StarBorderIcon from '@mui/icons-material/StarBorder';

export default function CustomImageList({ sortedActivityImages }: { sortedActivityImages: iActivityImage[]; }) {
    return (
        <ImageList
            gap={1}
            sx={{
                columnCount: {
                  xs: '1 !important',
                  sm: '2 !important',
                  md: '3 !important',
                  lg: '4 !important',
                  xl: '5 !important',
                },
              }}
        >
            {sortedActivityImages.map((item) => {
                const cols = item.coverPhoto ? 2 : 1;
                const rows = item.coverPhoto ? 2 : 1;

                return (
                    <ImageListItem key={item.id} cols={cols} rows={rows}>
                        <img
                            src={process.env.REACT_APP_IMAGE_BROWSER_URL + item.uniqueImageId}
                            alt={item.uniqueImageId}
                            loading="lazy"
                        />
                    </ImageListItem>
                );
            })}
        </ImageList>
    );
}
