import React, { useState } from "react";
import { LocalOffer } from '@mui/icons-material';
import { TextField, InputAdornment } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange, yellow } from "@mui/material/colors";
import { findText } from "../textUtil";
import { goldTileTagCheckReturn, tempGoldTileTagCheckOptions } from "../interfaces/activityInterfaces";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../features/store";
import { checkIfTempGoldTileTagIsAllowed } from "../features/activity/checkGoldTileTagSlice";

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: yellow,
        secondary: orange,
    },
    typography: {
        fontFamily: 'Comic Sans MS, Comic Sans, Marker Felt, cursive',
    },
});

const sxStyles = {
    textField: {
        width: 500,
        // mb: 2,
    },
};

type Props = {
    chosenLanguageId: number;
    uniqueUploadId: string;
    goldTileTagText: string;
    handleSetGoldTileTag: (goldTileTagUpdated: string) => void;
    // handleFinished: () => void;
};

const TreasureTagTextInput: React.FC<Props> = ({ chosenLanguageId, uniqueUploadId, goldTileTagText, handleSetGoldTileTag }) => {
    let autocompleteTimeout: NodeJS.Timeout;
    const [goldTileTag, setGoldTileTag] = useState<string>(goldTileTagText ? goldTileTagText : '');
    const [goldTileTagHelpText, setGoldTileTagHelpText] = useState<string>('');
    const useAppDispatch = () => useDispatch<AppDispatch>();
    const dispatch = useAppDispatch();

    const getText = (textName: string): string => {
        return findText(chosenLanguageId, textName);
    };

    const onChange = ({ target: { value } }): void => {
        const cleanedValue = value.replace(/\\/g, '').replace(/[^a-zA-Z\d_À-ÿ]/g, '_').toLowerCase();
        let fieldValid = true;
        setGoldTileTagHelpText(fieldValid ? '' : getText("validation_gold_tile_tag"));//will never happen because value is cleaned
        if (cleanedValue && cleanedValue.length > 30) {
            setGoldTileTagHelpText(getText("text_too_long"));
            fieldValid = false;
        } else if (cleanedValue && cleanedValue.length > 0 && cleanedValue.length < 5) {
            setGoldTileTagHelpText(getText("validation_gold_tile_tag"));
            fieldValid = false;
        }
        setGoldTileTag(cleanedValue);
        if (fieldValid && cleanedValue && cleanedValue.length > 4) {
            const maxWaitTimeTyping = 500;
            clearTimeout(autocompleteTimeout);//reset typing timer
            const inputGoldTileTagOptions: tempGoldTileTagCheckOptions = {
                uniqueUploadId: uniqueUploadId,
                inputGoldTileTag: cleanedValue,
            };
            autocompleteTimeout = setTimeout(
                async () => {
                    const goldTileCheck: goldTileTagCheckReturn = await dispatch(checkIfTempGoldTileTagIsAllowed(inputGoldTileTagOptions)).unwrap();
                    if (goldTileCheck.isOk) {
                        setGoldTileTagHelpText("");
                        handleSetGoldTileTag(cleanedValue);
                    } else {
                        setGoldTileTagHelpText(getText("error_gold_tile_tag_not_allowed"));
                        handleSetGoldTileTag("");
                    }
                },
                maxWaitTimeTyping
            );
        } else {
            handleSetGoldTileTag("");
        }
    };

    const tag_text = getText("tag_text");

    return (
        <ThemeProvider theme={theme}>
            <TextField
                label={tag_text + (goldTileTag.length > 1 ? ' ' + goldTileTag.length + '/30' : '')}
                name="goldTileTag"
                required
                error={goldTileTagHelpText !== ''}
                helperText={goldTileTagHelpText}
                sx={sxStyles.textField}
                value={goldTileTag}
                onChange={onChange}
                margin="none"
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LocalOffer />
                        </InputAdornment>
                    ),
                }}
            />
        </ThemeProvider>
    );
};

export default TreasureTagTextInput;