export enum GoldTileType {
    image = 'IMAGE',
    audio = 'AUDIO',
    quiz = 'QUIZ',
    geocache = 'GEOCACHE',
    gift = 'GIFT',//R_10.1.10
}

export enum LetsdoActivityType {
    gpsTrackLog = 'GPS_TRACK',
    geofence = 'GEOFENCE'//NB!! Not an activity type....just tracking to create a geofence
}
export enum ToType {//NB! must be small letters to use in radio button (???) (will not work in radio buttons)
    private = 'PRIVATE',
    shared = 'SHARED',
    group = 'GROUP'
}

export interface iTempGoldTile {
    id: number,
    uniqueUploadId: string,//Valid for two hours. Used for generating URL
    goldTileType: GoldTileType,
    uniqueAccountId: string,//creator
    accountUniqueImageId: string,
    accountHashtag: string,
    accountName: string,
    uniqueImageId: string,
    uniqueAudioId: string | null,
    title: string,//optional
    description: string,//optional
    goldTileTag: string,
    jsonData: string | null,
    deleteAt: string,//Must use new Date()
    updated: string,//Must use new Date()
    created: string,//Must use new Date()
};

export interface iTempGoldTileSlice {
    tempGoldTile: iTempGoldTile | null;
    isLoading: boolean;
    hasFailed: boolean;
}

export interface iAnswer {
    answer: string,
    correct: boolean,
    helpText: string,
    selected: boolean;
};

export interface iQuestionWithAnswers {
    question: string,
    answers: Array<iAnswer>;
};

export type goldTileTagCheckReturn = {
    inputGoldTileTag: string;
    isOk: boolean;
};

export type tempGoldTileTagCheckOptions = {
    uniqueUploadId: string,
    inputGoldTileTag: string;
};

export interface iActivitySlice {
    activity: iActivity | null;
    isLoading: boolean;
    isError: boolean;
}

export interface iActivityImage {
    id: number,
    uniqueActivityId: string,
    latitude: string,
    longitude: string,
    uniqueImageId: string,
    uniqueVideoId: string | null,
    altitude: number,
    created: Date | string;//Must use new Date(), but when sending it it will be a date
    coverPhoto?: boolean;//R_10.0.8 => user can set any of the photos as over photo
};

export interface iActivity {
    uniqueActivityId: string,
    activityType: LetsdoActivityType,
    toType: ToType,
    uniqueAccountId: string,
    accountUniqueImageId: string | null,//should not be null
    accountHashtag: string,
    accountName: string,
    toUniqueAccountId: string | null,
    toUniqueImageId: string | null,
    toName: string | null,
    description: string | null,
    averageSpeed: number,
    maxSpeed: number,
    minSpeed: number,
    trailLength: number,
    elevationGain: number,
    elevationLoss: number,
    elevationMax: number,
    elevationMin: number,
    likes: number,
    latitude: string,
    longitude: string,
    isoCountryCode: string,
    country: string,
    city: string,
    uniqueImageId: string | null,
    uniqueVideoId: string | null,
    startTime: string,//Must use new Date(), but when sending it it will be a date
    endTime: string,//Must use new Date(), but when sending it it will be a date
    virtualDogId: number,
    virtualDogName: string,
    updated: string,//Must use new Date(), but when sending it it will be a date
    created: string,//Must use new Date(), but when sending it it will be a date
    activityImages: Array<iActivityImage>;
    squaresCount: number,
};