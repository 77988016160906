import React, { useState } from "react";
import { EditLocationOutlined } from '@mui/icons-material';
import { TextField, InputAdornment } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { orange, yellow } from "@mui/material/colors"
import { findText } from "../textUtil";

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: orange,
        secondary: yellow,
    },
    typography: {
        fontFamily: 'Comic Sans MS, Comic Sans, Marker Felt, cursive',
    },
})

const sxStyles = {
    textField: {
        width: 500,
        // mb: 2,
    },
}

type Props = {
    chosenLanguageId: number;
    titleText: string;
    handleSetTitle: (titleUpdated: string) => void;
    // handleFinished: () => void;
};

const TreasureTitleTextInput: React.FC<Props> = ({ chosenLanguageId, titleText, handleSetTitle}) => {
    const [title, setTitle] = useState<string>(titleText ? titleText : '');
    const [titleHelpText, setTitleHelpText] = useState<string>('');

    const getText = (textName: string): string => {
        return findText(chosenLanguageId, textName);
    };

    const onChange = ({ target: { value } }): void => {
        let fieldValid: boolean = true;
        const cleanedValue: string = value.replace(/\\/g, '');
        if (cleanedValue && cleanedValue.length > 64) {
            setTitleHelpText(getText("text_too_long"));
            fieldValid = false;
        } else if (cleanedValue && cleanedValue.length > 0 && cleanedValue.length < 2) {
            setTitleHelpText(getText("text_too_short"));
            fieldValid = false;
        } else {
            setTitleHelpText('');
        }
        setTitle(cleanedValue);
        if (fieldValid) {
            handleSetTitle(cleanedValue);
        } else {
            handleSetTitle("");
        }
    };

    const treasure_title = getText("treasure_title");

    return (
        <ThemeProvider theme={theme}>
            <TextField
                label={treasure_title + (title.length > 1 ? ' ' + title.length + '/64' : '')}
                name="title"
                required
                error={titleHelpText !== ''}
                helperText={titleHelpText}
                sx={sxStyles.textField}
                value={title}
                onChange={onChange}
                margin="none"
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EditLocationOutlined />
                        </InputAdornment>
                    ),
                }}
            />
        </ThemeProvider>
    );
};

export default TreasureTitleTextInput;