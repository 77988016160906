import { Gavel } from '@mui/icons-material';
import { Box, Grid, Link, Typography } from '@mui/material';
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import { lightGreenColor } from '../common/colors';
import { findText } from '../common/textUtil';

//Not in use anymore
export default function PrivacyPolicy({ chosenLanguageId }) {

    const getText = (textName) => {
        return findText(chosenLanguageId, textName)
    }

    const privacy_policy = getText("privacy_policy")
    const privacy_policy_body_1 = getText("privacy_policy_body_1")
    const privacy_policy_body_2 = getText("privacy_policy_body_2")
    const privacy_policy_body_2_B = getText("privacy_policy_body_2_B")
    const privacy_policy_body_3 = getText("privacy_policy_body_3")
    const privacy_policy_body_4 = getText("privacy_policy_body_4")
    const privacy_policy_location_1 = getText("privacy_policy_location_1")
    const privacy_policy_location_2 = getText("privacy_policy_location_2")
    const privacy_policy_location_4 = getText("privacy_policy_location_4")
    const privacy_policy_data_deletion_1 = getText("privacy_policy_data_deletion_1")
    const privacy_policy_data_deletion_2 = getText("privacy_policy_data_deletion_2")
    const privacy_policy_data_deletion_3 = getText("privacy_policy_data_deletion_3")
    const terms_in_app_purchase_1 = getText("terms_in_app_purchase_1");
    const terms_in_app_purchase_2 = getText("terms_in_app_purchase_2");
    const terms_in_app_purchase_3 = getText("terms_in_app_purchase_3");
    const terms_in_app_purchase_4 = getText("terms_in_app_purchase_4");
    const terms_in_app_purchase_5 = getText("terms_in_app_purchase_5");
    const terms_in_app_purchase_6 = getText("terms_in_app_purchase_6");
    const terms_in_app_purchase_7 = getText("terms_in_app_purchase_7");

    const go_back = getText("go_back")
    return (
        <>
            <Helmet>
                <title>{privacy_policy}</title>
                <link rel="canonical" href="https://letsdo.app" />
            </Helmet>
            <Box
                // height="100vh"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 10, mb: 10 }}
            >
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    sx={{ width: "80%" }}
                >
                    <Grid item xs={12} align="center">
                        <Gavel sx={{ mt: 1 }} />
                        <Typography variant="h6" sx={{ m: 1, color: 'primary.main' }}>
                            {privacy_policy}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_body_1}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
                            {privacy_policy_body_2}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
                            {privacy_policy_body_2_B}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_body_3}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_body_4}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="h6" sx={{ m: 1, color: 'primary.main' }}>
                            {privacy_policy_location_1}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_location_2}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_location_4}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="h6" sx={{ m: 1, color: 'primary.main' }}>
                            {privacy_policy_data_deletion_1}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_data_deletion_2}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_data_deletion_3}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="h6" sx={{ m: 1, color: 'primary.main' }}>
                            {terms_in_app_purchase_1}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {terms_in_app_purchase_2}
                        </Typography>
                        <Typography variant="caption" >
                            {terms_in_app_purchase_3}
                        </Typography>
                        <Typography variant="caption" >
                            {terms_in_app_purchase_4 + " "}
                            <a style={{ color: lightGreenColor }} href={terms_in_app_purchase_5}>{terms_in_app_purchase_5}</a>.&nbsp;
                        </Typography>
                        <Typography variant="caption" >
                            {terms_in_app_purchase_6}
                        </Typography>
                        <Typography variant="caption" >
                            {terms_in_app_purchase_7}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Link to="/" component={RouterLink}>{go_back}</Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
